/**
 * @callback LogMessageFn
 * @param {import('./Level').LoggerLevel} level
 * @param {string} scope
 * @param {string} time
 * @param {Array<any>} messages
 */ class $204321697da1522e$var$Logger {
    /**
     * @private
     * @type {string}
     */ scope = null;
    /**
     * @private
     * @type {LogMessageFn}
     */ logMessageFn = null;
    /**
     * 
     * @param {string} scope 
     * @param {LogMessageFn} logMessage 
     */ constructor(scope, logMessage){
        this.scope = scope;
        this.logMessageFn = logMessage;
    }
    error(...args) {
        this.log("error", ...args);
    }
    warning(...args) {
        this.log("warning", ...args);
    }
    info(...args) {
        this.log("info", ...args);
    }
    debug(...args) {
        this.log("debug", ...args);
    }
    verbose(...args) {
        this.log("verbose", ...args);
    }
    /**
     * 
     * @param {import('./Level').LoggerLevel} level 
     * @param  {...any} args 
     */ log(level, ...args) {
        let time = new Date().toISOString();
        this.logMessageFn(level, this.scope, time, args);
    }
}
var $204321697da1522e$export$2e2bcd8739ae039 = $204321697da1522e$var$Logger;


/**
 * @typedef LoggerLevel
 * @type {('silent'|'error'|'warning'|'info'|'debug'|'verbose')}
 */ const $efac4ef6262a23c0$var$LevelOrder = {
    silent: -1,
    error: 0,
    warning: 1,
    info: 2,
    debug: 3,
    verbose: 4
};
/**
 * @enum {string}
 */ const $efac4ef6262a23c0$var$Level = {
    SILENT: "silent",
    ERROR: "error",
    WARNING: "warning",
    INFO: "info",
    DEBUG: "debug",
    VERBOSE: "verbose"
};
/**
 * 
 * @param {LoggerLevel} level 
 */ const $efac4ef6262a23c0$export$82b3222de2ae88eb = (level)=>{
    let order = $efac4ef6262a23c0$var$LevelOrder[level] || null;
    if (typeof order !== "number") return -1;
    return order;
};
/**
 * 
 * @param {number} levelOrder 
 * @returns {LoggerLevel}
 */ const $efac4ef6262a23c0$export$bbb5699fb5728e4f = (levelOrder)=>{
    let level = Object.keys($efac4ef6262a23c0$var$LevelOrder).find((key)=>$efac4ef6262a23c0$var$LevelOrder[key] === levelOrder) || null;
    if (level === null) return "silent";
    return level;
};
var $efac4ef6262a23c0$export$2e2bcd8739ae039 = $efac4ef6262a23c0$var$Level;




class $9252cb9b38bd57ee$var$LogReporter {
    /**
     * 
     * @param {import('./Level').LoggerLevel} level 
     * @param {string} scope 
     * @param {string} time
     * @param {Array<any>} messages 
     */ log(level, scope, time, messages) {}
}
var $9252cb9b38bd57ee$export$2e2bcd8739ae039 = $9252cb9b38bd57ee$var$LogReporter;


class $3c9bfc1514d27ccb$var$LoggerFactory {
    /**
     * @private
     * @type {Map<string,Logger>}
     */ loggers = new Map();
    /**
     * @private
     * @type {Array<LogReporter>}
     */ reporters = [];
    /**
     * @private
     * @type {number}
     */ levelOrder = null;
    /**
     * 
     * @param {Array<LogReporter>} reporters 
     */ constructor(reporters = []){
        this.reporters = reporters;
        this.level = "info";
    }
    set level(level) {
        this.levelOrder = (0, $efac4ef6262a23c0$export$82b3222de2ae88eb)(level);
    }
    get level() {
        return (0, $efac4ef6262a23c0$export$bbb5699fb5728e4f)(this.levelOrder);
    }
    getLogger(scope = "default") {
        if (!this.loggers.has(scope)) {
            let logger = new (0, $204321697da1522e$export$2e2bcd8739ae039)(scope, this.onLog);
            this.loggers.set(scope, logger);
        }
        return this.loggers.get(scope);
    }
    /**
     * @private
     * @param {import('./Level').LoggerLevel} level 
     * @param {string} scope 
     * @param {string} time
     * @param {Array<any>} messages
     */ onLog = (level, scope, time, messages)=>{
        let order = (0, $efac4ef6262a23c0$export$82b3222de2ae88eb)(level);
        if (this.levelOrder < order) return;
        for (let reporter of this.reporters)reporter.log(level, scope, time, messages);
    };
}
var $3c9bfc1514d27ccb$export$2e2bcd8739ae039 = $3c9bfc1514d27ccb$var$LoggerFactory;




class $7a070ee46550673d$var$ConsoleLogReporter extends (0, $9252cb9b38bd57ee$export$2e2bcd8739ae039) {
    /**
     * 
     * @param {import('./Level').LoggerLevel} level 
     * @param {string} scope 
     * @param {string} time
     * @param {Array<any>} messages 
     */ log(level, scope, time, messages) {
        if (level === "error") console.error(`${level.toUpperCase()} [${time}] | ${scope}:`, ...messages);
        else if (level === "warning") console.warn(`${level.toUpperCase()} [${time}] | ${scope}:`, ...messages);
        else console.log(`${level.toUpperCase()} [${time}] | ${scope}:`, ...messages);
    }
}
var $7a070ee46550673d$export$2e2bcd8739ae039 = $7a070ee46550673d$var$ConsoleLogReporter;


const $f7c18e675abb731d$export$45697122e627faf2 = new (0, $3c9bfc1514d27ccb$export$2e2bcd8739ae039)([
    new (0, $7a070ee46550673d$export$2e2bcd8739ae039)()
]);
var $f7c18e675abb731d$export$2e2bcd8739ae039 = $f7c18e675abb731d$export$45697122e627faf2;


export {$f7c18e675abb731d$export$45697122e627faf2 as logging, $f7c18e675abb731d$export$2e2bcd8739ae039 as default, $204321697da1522e$export$2e2bcd8739ae039 as Logger, $efac4ef6262a23c0$export$2e2bcd8739ae039 as Level, $3c9bfc1514d27ccb$export$2e2bcd8739ae039 as LoggerFactory, $9252cb9b38bd57ee$export$2e2bcd8739ae039 as LogReporter, $7a070ee46550673d$export$2e2bcd8739ae039 as ConsoleLogReporter};
